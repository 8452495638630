<template>
  <el-card v-loading="isLoading" shadow="always">
    <template #header>
      <el-row type="flex" justify="space-between" align="center">
        <el-col :md="8" :sm="8" :xs="10"
          ><el-row type="flex" justify="start"
            ><h4>{{ $route.name }}</h4></el-row
          ></el-col
        >
        <el-col :md="16" :sm="16" :xs="14">
          <el-row type="flex" justify="end">
            <el-col :md="24">
              <el-row :gutter="4" type="flex" justify="end" align="center">
                <el-col :md="12" align="center">
                  <el-row type="flex" justify="end" style="margin-top: 5px">
                    <el-dropdown
                      @command="(c) => (cashierStatus = c) | fetchCashiers()"
                      size="medium"
                      trigger="click"
                    >
                      <el-button type="primary" size="medium">
                        Filtrar por: {{ CashierStatus[cashierStatus] || ""
                        }}<i class="el-icon-arrow-down el-icon--right"></i>
                      </el-button>
                      <template #dropdown>
                        <el-dropdown-menu>
                          <el-dropdown-item
                            command="created"
                            v-if="canAny('AcceptCashierClose')"
                          >
                            <li
                              :class="{
                                'el-dropdown-menu__item': true,
                                'no-padding': true,
                                active: cashierStatus === 'created',
                              }"
                            >
                              Abertos
                            </li>
                          </el-dropdown-item>
                          <el-dropdown-item
                            command="rejected"
                            v-if="canAny('AcceptCashierClose')"
                            ><li
                              :class="{
                                'el-dropdown-menu__item': true,
                                'no-padding': true,
                                active: cashierStatus === 'rejected',
                              }"
                            >
                              Rejeitados
                            </li></el-dropdown-item
                          >
                          <el-dropdown-item
                            command="checked"
                            v-if="canAny('FinalizeCashierClose')"
                            ><li
                              :class="{
                                'el-dropdown-menu__item': true,
                                'no-padding': true,
                                active: cashierStatus === 'checked',
                              }"
                            >
                              Conferidos
                            </li></el-dropdown-item
                          >
                          <el-dropdown-item
                            command="completed"
                            v-if="canAny('FinalizeCashierClose')"
                            ><li
                              :class="{
                                'el-dropdown-menu__item': true,
                                'no-padding': true,
                                active: cashierStatus === 'completed',
                              }"
                            >
                              Finalizados
                            </li></el-dropdown-item
                          >
                        </el-dropdown-menu>
                      </template>
                    </el-dropdown>
                  </el-row>
                </el-col>
                <el-button
                  type="primary"
                  icon="el-icon-s-operation"
                  class="mb-0"
                  size="medium"
                  @click="openFilterCashierModal()"
                ></el-button>
              </el-row>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </template>
    <el-table
      stripe
      :cell-style="() => 'text-align:center;'"
      :data="Cashiers"
      style="width: 100%; z-index: 0"
    >
      <el-table-column prop="firm.name" label="açougue"></el-table-column>
      <el-table-column prop="op" label="operador">
        <template #default="c">
          {{ c?.row?.op }}
        </template>
      </el-table-column>
      <el-table-column label="data" :formatter="(r) => formatDate(r.closed_at)">
      </el-table-column>
      <el-table-column label="geral">
        <template #default="r">
          <div
            :class="{
              'is-negative': calculateTotalGlobal(r.row) < 0,
              'is-positive': calculateTotalGlobal(r.row) >= 0,
            }"
          >
            {{ currencyFormatter.format(calculateTotalGlobal(r.row)) }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
        label="finalizadora"
        :formatter="(r) => currencyFormatter.format(Number(r.total_sells) || 0)"
      ></el-table-column>
      <el-table-column
        label="sangria"
        :formatter="(r) => currencyFormatter.format(Number(r.withdrawal) || 0)"
      ></el-table-column>
      <el-table-column
        label="total em caixa"
        prop="total_cashier"
        :formatter="
          (r) => currencyFormatter.format(Number(r.total_cashier) || 0)
        "
      ></el-table-column>
      <el-table-column
        label="conferência"
        prop="total_cashier"
        :formatter="(r) => currencyFormatter.format(Number(r.check_total) || 0)"
      ></el-table-column>
      <el-table-column label="resultado" prop="total_cashier">
        <template #default="r">
          <div
            :class="{
              'is-negative': calculateResult(r.row) < 0,
              'is-positive': calculateResult(r.row) >= 0,
            }"
          >
            {{ currencyFormatter.format(calculateResult(r.row)) }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="ações" :min-width="150">
        <template #default="emp">
          <el-row type="flex" justify="end">
            <el-button-group>
              <el-popconfirm
                v-if="['checked'].includes(cashierStatus)"
                @confirm="acceptClosing(emp.row)"
                title="
              Finalizar fechamento de caixa?
            "
                ><template #reference>
                  <el-button onlyIcon type="primary" circle>
                    <i class="el-icon-finished"></i></el-button></template
              ></el-popconfirm>
              <el-popconfirm
                v-if="['completed'].includes(cashierStatus)"
                @confirm="updateStatus(emp.row, 'checked')"
                title="
              Retornar para conferidos?
            "
                ><template #reference>
                  <el-button onlyIcon type="warning" circle>
                    <i class="el-icon-bottom-left"></i></el-button></template
              ></el-popconfirm>
              <el-button
                onlyIcon
                type="primary"
                circle
                @click="openCashierCloseInfoModal(emp.row)"
              >
                <i class="el-icon-tickets"></i
              ></el-button>
              <el-button
                onlyIcon
                type="primary"
                circle
                v-if="['checked', 'completed'].includes(cashierStatus)"
                @click="openCashierResultInfoModal(emp.row)"
              >
                <i class="el-icon-document-checked"></i
              ></el-button>
              <el-popconfirm
                @confirm="deleteCashier(emp.row)"
                title="
              Remover fechamento?
            "
                ><template #reference>
                  <el-button onlyIcon type="danger" circle>
                    <i class="el-icon-delete"></i></el-button></template
              ></el-popconfirm>
            </el-button-group>
          </el-row>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @update:current-page="currentPage = $event"
      :current-page="currentPage"
      background
      layout="prev, pager, next"
      :page-count="cashiers?.lastPage"
    >
    </el-pagination>
    <cashier-close-info-modal
      :showModal="showCashierCloseInfoModal"
      :cashier="cashier"
      @close-modal="showCashierCloseInfoModal = false"
      @should-update="fetchCashiers"
    ></cashier-close-info-modal>
    <cashier-result-info-modal
      :showModal="showCashierResultInfoModal"
      :cashier="cashier"
      @close-modal="showCashierResultInfoModal = false"
      @should-update="fetchCashiers"
    ></cashier-result-info-modal>
    <filter-cashier-modal
      :showModal="showFilterCashierModal"
      @close-modal="showFilterCashierModal = false"
      @update-filters="updateFilters"
    ></filter-cashier-modal>
  </el-card>
</template>

<script>
import { ElNotification /*ElMessageBox*/ } from "element-plus";
import CashierCloseInfoModal from "./modals/CashierCloseInfoModal.vue";
import FilterCashierModal from "./modals/FilterCashierModal.vue";
import CashierResultInfoModal from "./modals/CashierResultInfoModal.vue";

export default {
  name: "CashierCloseCheck",
  components: {
    CashierCloseInfoModal,
    CashierResultInfoModal,
    FilterCashierModal,
  },
  data: () => ({
    cashierStatus: "created",
    showCashierCloseInfoModal: false,
    showCashierResultInfoModal: false,
    showFilterCashierModal: false,
    cashiers: null,
    currentPage: 1,
    cashier: null,
    filterOptions: {},
    isLoading: false,
    dateFormatter: new Intl.DateTimeFormat("pt-BR", {
      dateStyle: "short",
    }),
    currencyFormatter: new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }),
    numberFormatter: new Intl.NumberFormat("pt-BR", {
      style: "decimal",
    }),
  }),
  mounted() {
    this.fetchCashiers();
  },
  computed: {
    Cashiers() {
      return this.cashiers?.data || [];
    },
    Can() {
      return this.$store.state.auth.can;
    },
    CashierStatus() {
      return {
        created: "Aberto",
        rejected: "Rejeitado",
        checked: "Conferido",
        completed: "Finalizado",
      };
    },
  },
  watch: {
    currentPage() {
      this.fetchCashiers();
    },
  },
  methods: {
    canAny() {
      for (let a in arguments) if (this.Can(arguments[a])) return true;

      return false;
    },
    openCashierResultInfoModal(cashier) {
      this.cashier = cashier;
      this.showCashierResultInfoModal = true;
    },
    resetPagination() {
      this.currentPage = 1;
    },
    openFilterCashierModal() {
      this.showFilterCashierModal = true;
    },
    updateFilters(filters) {
      this.filterOptions = filters || {};
      this.resetPagination();
      this.fetchCashiers();
    },
    acceptClosing(closing) {
      if (closing)
        this.updateCashiers({
          uid: closing.uid,
          status: "completed",
          accepted_by: this.$store.state?.user?.uid,
        });
    },
    updateStatus(cashier, status) {
      if (cashier) {
        cashier.status = status;
        this.updateCashiers(cashier);
      }
    },
    openCashierCloseInfoModal(cashier) {
      this.cashier = cashier;
      this.showCashierCloseInfoModal = true;
    },
    formatDate(c) {
      if (new Date(`${c}`) != "Invalid Date")
        return this.dateFormatter.format(new Date(c));
      else return "desconhecido";
    },
    deleteCashier(cashier) {
      if (cashier)
        fetch(`${this.$store.state.apiUrl}cashiers/${cashier.uid}`, {
          credentials: "include",
          method: "DELETE",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
          .then((response) => {
            if (response.status === 200) return response.json();
            else throw response.json();
          })
          .then(() => {
            this.fetchCashiers();
            this.isLoading = false;
            this.$notify({
              title: "Fechamento de caixa removido com sucesso.",
              type: "success",
              position: "bottom-right",
            });
          })
          .catch(async (e) => {
            this.$notify({
              title: "Não foi possível remover o fechamento de caixa",
              type: "error",
              message: (await e).message,
              position: "bottom-right",
            });
          });
    },
    updateCashiers(cashier) {
      if (cashier)
        fetch(`${this.$store.state.apiUrl}cashiers/${cashier.uid}`, {
          credentials: "include",
          method: "PUT",
          body: JSON.stringify({ ...cashier }),
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
          .then(async (response) => {
            if (response.status == 200) return await response.json();
            else throw await response.json();
          })
          .then(
            (e) => {
              this.fetchCashiers();
              ElNotification.success({
                title: e.message,
                position: "bottom-right",
              });
            },
            (e) => {
              ElNotification.error({
                title: "Não foi possível alterar o fechamento de caixa",
                message: e.message,
                position: "bottom-right",
              });
            }
          );
    },
    calculateResult(r) {
      return Number(Number(r.total_cashier) - Number(r.check_total)) || 0;
    },
    calculateTotalGlobal(r) {
      return (
        Number(r.total_sells) +
          Number(r.discount) +
          Number(r.canceled) +
          Number(r.start) || 0
      );
    },
    fetchCashiers() {
      this.isLoading = true;
      const url = new URL(`${this.$store.state.apiUrl}cashiers`);
      url.search = new URLSearchParams({
        status: this.cashierStatus,
        pagination: this.currentPage,
        ...(this.filterOptions || {}),
      });
      fetch(url, {
        credentials: "include",
      })
        .then((response) => {
          if (response.status === 200) return response.json();
          else return response.text();
        })
        .then((json) => {
          this.cashiers = json;
        })
        .catch(() => (this.hasError = true))
        .finally(() => (this.isLoading = false));
    },
  },
};
</script>
<style scoped>
.el-card {
  width: 100%;
}
tr.unavailable {
  background-color: #fffde7;
}
.is-negative {
  color: red;
}
.is-positive {
  color: blue;
}
tr.problem {
  background-color: #fbe9e7;
}
.mb-0 {
  margin-bottom: 0px !important;
}
</style>
